
import './App.css';

import { RouterPrincipal } from './components/router/RouterPrincipal';



function App() {
  return (
    <>
      
      <RouterPrincipal/>
     
    </>
  );
}

export default App;
